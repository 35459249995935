import React from "react"
import Head from "next/head"

import { TMeta } from "../../../types/layoutTypes"

type Props = {
  meta: TMeta
}

const PageMeta = ({
  meta = {
    title: "MyJobs.ge",
    desc: ""
  }
}: Props) => {
  return (
    <Head>
      <title>{meta.title}</title>
      <meta name="title" property="og:title" content={meta.title} key="title" />
      {/* <meta name="description" content={meta.desc} /> */}
      <meta content={meta.keywords} name="keywords" />
      <meta name="description" property="og:description" content={meta.desc} key="description" />
      {/* <meta name="image" content={meta.img} /> */}
      <meta name="image" property="og:image" content={meta.img} key="image" />
      <meta property="og:url" content={meta.url} key="url" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0" />
      <link rel="canonical" href={meta.canonical} />
      <link rel="alternate" href="https://myjobs.ge/ka" hrefLang="ka" />
      <link rel="alternate" href="https://myjobs.ge/en" hrefLang="en" />
      <link rel="alternate" href="https://myjobs.ge/ka" hrefLang="x-default" />
    </Head>
  )
}

export default PageMeta
